.stackHolder{
    width: 300px;
    height: 150px;
    border: 1px solid #5F5F5F;
    position: relative;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stackAuthor{
    position: absolute;
    top: 30px;
    left: 15px;
    color: lightgrey;
    font-size: 14px;
}

.stackTitle{
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 18px;
}

.stackFollow{
    position:absolute;
    font-size: 13px;
    top: 10px;
    right: 15px;
    color: #6CFF69;
    padding: 5px;
    border: 1px solid #6CFF69;
    border-radius: 10px;
}

.stackFollowers{
    position: absolute;
    bottom: 10px;
    left: 15px;
    display: flex;
    flex-direction: row;
}

.stackFollowersText{
    margin-left: 3px;
    font-size: 15px;
    color: #6D6D6D;
}

.stackDescription{
    font-size: 13px;
    width: 90%;
    height: 35px;
    position: absolute;
    bottom: 50px;
    left: 15px;
    color: #636363;
    overflow: hidden;
    text-overflow: ellipsis;
}

