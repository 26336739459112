.options{
    height: 25%;
    align-content: center;
    position: relative;
}

.optionsText{
    width: 30%;
    position: absolute;
    left: 40%;
    height: 50%;
    top:40%;
    border-left: 1px solid #5F5F5F;
    color: white;
}


.mainContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.navBar{
    width: 30%;
    background-color: black;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
}

.HomePageContainer{
    width: 100%;
}

ul{
    list-style-type: none;
    line-height: 30px;
    font-size: 20px;
    font-weight: bold;
}

li {
    text-decoration: none;
}

