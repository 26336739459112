


.options{
    height: 25%;
    align-content: center;
    position: relative;
}

.addPost{
    height: 8%;
}

.homePageButton{
    width: 25%;
    height: 4%;
    border: 1px solid grey;
    border-radius: 15px;
    position: absolute;
    left: 60%;
    background-color: white;
    font-size: 15px;
    font-weight: bold;
}

.home{
    position: relative;
    height: 100%;
    width: 100%;
}

.homeFeedOverall{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.homeFeed{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    overflow-y: scroll;

}

.homeStacks{
    display:flex;
    flex-direction: column;
    width: 30%;
    margin-left: 40px;
}

.searchBar{
    width: 50%;
    height: 30px;
    border: 1px solid #5F5F5F;
    text-align: left;
    position: relative;
}

.searchText{
    position: absolute;
    top: 15%;
    left: 3%;
    width: 80%;
    border-bottom: 1px solid #5F5F5F;
    color: #5F5F5F
}

.postContainer{
    height: 200px;
    width: 80%;
    color: white;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    overflow: hidden;
}