.page{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.top{
    display: flex;
    justify-content: center;
    height: 8%;
}

.border_bottom{
    width: 90%;
    border-bottom: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo{
    width: 10%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoImage{
    width: 60px;
    height: 70px;

}
.bottom{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 10%;
    background-color: grey;
    margin-top: 100px;
}

.left{
    display:flex;
    flex-direction:column;
    margin-right: 30px;
    height: 100%;
    width: 30%;
    align-items: center;
    justify-content: center;
}

.middleHalf{
    height: 30%;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title2{
    height: 40%;
    width: 10%;
    color: rgb(202, 201, 202); 
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
}

.description{
    font-size: 22px;
    width: 30%;
    color: white;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
    text-align: center;
}

.network{
    display:flex;
    align-items: center;
    justify-content: center;
}

.networkImage{
    height: 100%;
    width: 100%;
}

.right{
    display:flex;
    flex-direction:column;
    margin-left: 30px;
    height: 100%;
    width: 30%;
    align-items: center;
    justify-content: center;
}


.email{
    height: 45px;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.password{
    height: 45px;
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}

.titleInput {
    width: 100%;
    height: 20px;
    border: 1px solid black;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.postLabelContainer{
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-family: Arial;
}
.postDropdown{
    position: relative;
  font-family: Arial;
  width: 100%;
    height: 20px;
    border: 1px solid black;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.postSubmitButton{
    margin-top:10px;
    margin-left: 40px;
    width: 60px;
    color: blue;
    border: 1px solid black;
    border-radius: 15px;
}

.login{
    height: 45px;
    margin-bottom: 25px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loginButton{
    background-color: black; 
    width: 60%;
    height: 80%;
    border: 1px solid black;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; 
  }

.bottomHalf{
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid black;
}

.signup{
    height: 40%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signupButton{
    background-color: black;
    color: white;
    font-size: 18px;
    border: 1px solid black;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; 
    width: 60%;
    height: 80%;
}

.auth{
    background-color: red;
}

.signedInHome{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.signedInHeader{
    color: white;
    height: 4%;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
}

.signedInUser{
    position: absolute;
    left: 5px;
    top: 10px;
}
.signOutButtonContainer{
    position: absolute;
    right: 5px;
    top: 10px;
}
.signOutButton{
    border: 1px solid white;
    padding:5px;
    border-radius: 10px;
}

.firebaseUI{
    width: 100%;
    background-color: black;
    height: 100%;
}