.card{
    border: 1px solid #5F5F5F;
    position: relative;
    width: 98%;
    height: 100px;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.expandedCard{
    border: 1px solid #5F5F5F;
    position: relative;
    width: 98%;
    height: 450px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.expandedMiddleBox{
    height: 100%;
    background-color: #181818;
    width: 80%;
}
.expandedMiddleBoxHolder{
    height: 70%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #6CFF69;
    border-bottom: 1px solid #6CFF69;
}

.cardContainer{
    height: 100px;
    display: flex;
    flex-direction: column;
}
.title{
    position: absolute;
    top: 5px;
    left: 1%;
    white-space: nowrap;
}


.author{
    position: absolute;
    top: 25px;
    left: 1%;
    font-size: 14px;
    color: #848484;
}

.likes{
    position: absolute;
    bottom: 5px;
    left: 1%;
    width: 45px;
    color: gold;

}

.stackAddedTo{
    position: absolute;
    bottom: 5px;
    right: 1%;
}

.likesContainer{
    display: flex;
    flex-direction: row;
    border: 1px solid #5F5F5F;
    border-radius: 3px;
    position: relative;
}

.likesNum{
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 14px;
    color: #848484;
}

.likesIcon{
padding-left: 15px;
padding-top: 2px;
}