.profileCards{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: scroll;
}

.profileCardHolder{
    position: absolute;
    width: 60%;
    top: 220px;
}
.yourCardHolder{
    font-size: 20px;
    color: white;
    margin-bottom: 5px;
}

.profileInfo{
    display: flex;
    flex-direction: row;
    height: 150px;
    width: 60%;
    margin-top: -200px;
    position: absolute;
    top: 250px;
}

.profilePicture{
    width: 100px;
    height: 100px;
    border: 1px solid grey;
}

.profileInfoSubHolder{
    width: 30%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profileName{
    font-size: 20px;
    color: grey;
}

.profileBio{
    font-size:18px;
    color:white;
    height: 100%;
    width: 100%;
    margin-left: 10px;
    display: flex;
    align-items: center;
}
